import styled from '@emotion/styled';
import React from 'react';
import { Text } from 'theme-ui';

const StarBar = styled.div`
  --percent: ${(props) => props.percent}%;
  --star-size: 60px;
  --star-color: #fff;
  --star-background: ${(props) => props.theme.colors.primary};

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

function RatingStars({ rating, numerator, comparator }) {
  return (
    <div
      itemprop="aggregateRating"
      itemscope
      itemtype="https://schema.org/AggregateRating"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <StarBar
        percent={(rating / comparator) * 100}
        aria-label="Die GBH Vorsogeberatung ist mit ${rating} von ${comparator} Sternen bewertet."
      />

      <Text variant={'text.default'} as="p" sx={{ textAlign: 'center', mt: 4 }}>
        <span itemprop="ratingValue">{rating}</span> Sterne - in <span itemprop="reviewCount">{numerator}</span>{' '}
        Bewertungen
      </Text>
    </div>
  );
}

export default RatingStars;
