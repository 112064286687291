/** @jsx jsx */

import { graphql, navigate } from 'gatsby';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { jsx } from 'theme-ui';
import Button from '../components/Button';
import ContactCards from '../components/ContactCards';
import Heading from '../components/Heading';
import Hero from '../components/Hero';
import { ReactComponent as CaretRight } from '../components/Icon/assets/caret-right.svg';
import ModuleDemodesk from '../components/modules/ModuleDemodesk';
import ModuleCalendly from '../components/modules/ModuleCalendly';
import ModuleImageRow from '../components/modules/ModuleImageRow';
import ModuleList from '../components/modules/ModuleList';
import ModulePartner from '../components/modules/ModulePartner';
import ModuleRelatedContent from '../components/modules/ModuleRelatedContent';
import ModuleTeaser from '../components/modules/ModuleTeaser';
import ModuleTextCentered from '../components/modules/ModuleTextCentered';
import ModuleTextWithImage from '../components/modules/ModuleTextWithImage';
import Plaintext from '../components/Plaintext';
import Richtext from '../components/Richtext';
import Layout from '../global/Layout';
import ModuleCredit from '../components/modules/ModuleCredit';
import ModuleProvenExpert from '../components/modules/ModuleProvenExpert';

export default function Template({ data, pageContext }) {
  const { title, description, pageContent, _rawPageContent, _rawHero, hero } = data.sanityPage;

  const handleKeyDown = (e) => {
    e.keyCode === 39 && e.altKey && navigate(pageContext.next);
    e.keyCode === 37 && e.altKey && navigate(pageContext.prev);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const content = pageContent.map((component, index) => {
    if (component) {
      const { _type } = component;
      const variant = component.backgroundColor?.replace('backgrounds.', '') || 'container';

      switch (_type) {
        case 'moduleImageRow':
          return (
            <ModuleImageRow
              component={component}
              variant={variant}
              key={`${_type}_${index}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
            />
          );
        case 'moduleRelatedContent':
          return (
            <ModuleRelatedContent
              component={component}
              variant={variant}
              key={`${_type}_${index}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
            />
          );
        case 'moduleDemodesk':
          return (
            <ModuleDemodesk
              variant={variant}
              account={component.account}
              event={component.event}
              key={`${_type}_${index}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
            />
          );
        case 'moduleCalendly':
          return (
            <ModuleCalendly
              variant={variant}
              key={`${_type}_${index}`}
              {...component}
              // account={component.account}
              // event={component.event}
              // cookieConsent={component.cookieConsent}
              // cookieConsentLabel={component.cookieConsentLabel}
              // spaceAbove={component.spaceAbove}
              // spaceBelow={component.spaceBelow}
              // title={component.title}
            />
          );
        case 'modulePartner':
          return (
            <ModulePartner
              partner={component.partner}
              variant={variant}
              key={`${_type}_${index}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
            />
          );
        case 'moduleTextCentered':
          return (
            <ModuleTextCentered
              variant={variant}
              key={`${_type}_${index}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
              component={component}
              head={
                <Heading
                  sx={{ textAlign: ['left', null, 'center'], color: 'inherit' }}
                  as={'h2'}
                  variant="h2"
                  intro={component.subTitle}
                >
                  {component.title}
                </Heading>
              }
            >
              <Richtext content={_rawPageContent[index].copy} sx={{ textAlign: ['left', null, 'center'] }} />
              {component.button && (
                <Button variant={component.button.buttonType} to={component.button.reference?.slug?.current}>
                  {component.button.buttonCaption}
                </Button>
              )}
            </ModuleTextCentered>
          );
        case 'moduleContacts':
          return component.contacts.map((contact, contactIndex) => (
            <ContactCards
              variant={variant}
              key={`${_type}_${contactIndex}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
              firstName={contact.firstName}
              id={contact.id}
              lastName={contact.lastName}
              role={contact.role}
              workEmail={contact.workEmail}
              workPhone={contact.workPhone}
              workFax={contact.workFax}
              bio={<Richtext content={contact._rawCopy} />}
              showDownload={contact.showDownload}
              showBooking={contact.showBooking}
              image={contact.image}
            />
          ));

        case 'moduleImageWithText':
          return (
            <ModuleTextWithImage
              variant={variant}
              key={`${_type}_${index}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
              img={component.image}
              imagePosition={component.imagePosition}
              showDecorativeElements={component.showDecorativeElements}
              alt={component.image.alt}
              title={component.image.caption}
            >
              {component.title && (
                <Heading as={'h2'} variant="h2" sx={{ textAlign: 'left', mb: 4 }} intro={component.subTitle}>
                  {component.title}
                </Heading>
              )}

              <Richtext content={_rawPageContent[index].copy} />
              {component.button && (
                <Button
                  variant={component.button.buttonType}
                  to={component.button.reference?.slug?.current}
                  iconAfter={component.button.buttonType === 'text' ? CaretRight : null}
                >
                  {component.button.buttonCaption}
                </Button>
              )}
            </ModuleTextWithImage>
          );

        case 'moduleList':
          return (
            <ModuleList
              items={component.items}
              type={component.type}
              variant={variant}
              key={`${_type}_${index}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
            >
              <Heading as={'h2'} variant="h2" sx={{ textAlign: 'left', color: 'inherit' }} intro={component.subTitle}>
                {component.title}
              </Heading>
            </ModuleList>
          );
        case 'moduleCredit':
          return (
            <ModuleCredit
              key={`${_type}_${index}`}
              subTitle={component.subTitle}
              title={component.title}
              variant={variant}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
            />
          );

        case 'moduleTeaser':
          return (
            <ModuleTeaser
              variant={variant}
              key={`${_type}_${index}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
            >
              <Heading
                as={'p'}
                variant="h2"
                sx={{ textAlign: 'center', color: 'inherit', mb: 6 }}
                intro={component.subTitle}
              >
                {component.title.split('\n').map((line, index) => (
                  <div key={`${component._key}_${index}`}>{line}</div>
                ))}
              </Heading>
              <Button
                variant={component.cta.buttonType}
                to={component.cta.externalURL || component.cta.reference?.slug?.current}
                sx={{ mx: 'auto', mt: 6, justifyContent: 'center', width: 'max-content' }}
              >
                {component.cta.buttonCaption}
              </Button>
            </ModuleTeaser>
          );

        case 'moduleProvenExpert':
          return (
            <ModuleProvenExpert
              variant={variant}
              key={`${_type}_${index}`}
              spaceAbove={component.spaceAbove}
              spaceBelow={component.spaceBelow}
              subTitle={component.subTitle}
              cta={component.cta}
            ></ModuleProvenExpert>
          );
      }
    }
  });

  return (
    <Layout>
      <Helmet>
        <title>{data.sanityPage.title}</title>
        <meta property="og:title" content={`${data.sanityPage.title} | ${data.site.siteMetadata.title}`} />
        <meta property="og:description" content={data.sanityPage.description ? data.sanityPage.description : ''} />
        <meta property="description" content={data.sanityPage.description ? data.sanityPage.description : ''} />
        <meta property="og:image" content={hero?.image?.ogImage.gatsbyImageData.images.fallback.src} />
      </Helmet>
      
      {hero && (
        <Hero img={hero.image || ''}>
          {hero.title && (
            <Heading variant={'h1'} as={'h1'} sx={{ mb: 6 }} intro={hero.subtitle}>
              {hero.title}
            </Heading>
          )}
          {_rawHero.intro && <Richtext content={_rawHero.intro} sx={{ textAlign: 'left' }} />}
          {hero?.cta?.buttonCaption && (
            <Button variant={hero.cta.buttonType} to={hero.cta.externalURL || hero.cta.reference?.slug?.current}>
              {hero.cta.buttonCaption}
            </Button>
          )}
        </Hero>
      )}
      <article className="content-container">{content && content}</article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    sanityPage(id: { eq: $id }) {
      title
      description
      _rawHero
      hero {
        _type
        cta {
          buttonCaption
          buttonType
          externalURL
          _type
          reference {
            slug {
              current
            }
          }
        }
        title
        subtitle
        image {
          
          ogImage: asset {
            gatsbyImageData(width: 1200, height: 630)
          },
          bg480: asset {
            gatsbyImageData(width: 480)
          },
          bg672: asset {
            gatsbyImageData(width: 336)
          },
          bg768: asset {
            gatsbyImageData(width: 384)
          },
          bg1024: asset {
            gatsbyImageData(width: 512)
          },
          bg1200: asset {
            gatsbyImageData(width: 600)
          },
        }
      }
      _rawPageContent
      pageContent {
        ... on SanityModuleCredit {
          _key
          _type
          subtitle
          title
          spaceAbove
          spaceBelow
          backgroundColor
        }
         ... on SanityModuleContacts {
           _key
           _type
           spaceAbove
           spaceBelow
           backgroundColor
           contacts {
             _rawCopy
             firstName
             id
             lastName
             role
             workEmail
             workPhone
             workFax
             city
             showDownload
             showBooking
             image {
              _key
              ...ImageDataTextWithImage
             }
           }
         }
 ... on SanityModuleImageRow {
   _key
   _type
   spaceAbove
   spaceBelow
   backgroundColor
   images {
     alt
     caption
     ...ImageDataImageRow
   }
 }
 ... on SanityModuleRelatedContent {
   _key
   _type
   spaceAbove
   spaceBelow
   backgroundColor
   relatedContent {
     id
     title
     hero {
       title
       image {
        ...ImageDataRelatedContent
       }
     }
     slug {
       current
     }
   }
 }
         ... on SanityModuleTeaser {
           _key
           _type
           spaceAbove
           spaceBelow
           backgroundColor
           subTitle
           title
           cta {
             buttonCaption
             buttonType
             externalURL
             reference {
               slug {
                 current
               }
             }
           }
         }
         ... on SanityModuleProvenExpert {
           _key
           _type
           spaceAbove
           spaceBelow
           backgroundColor
           subTitle
           cta {
             buttonCaption
             buttonType
             externalURL
             reference {
               slug {
                 current
               }
             }
           }
         }
         ... on SanityModuleImageWithText {
           _key
           _type
           spaceAbove
           spaceBelow
           backgroundColor
           showDecorativeElements
           title
           subTitle
           imagePosition
           image {
             _type
             alt
             caption
             ...ImageDataTextWithImage
           }
           button {
             buttonCaption
             buttonType
             reference {
               id
               slug {
                 current
               }
             }
           }
         }
 ... on SanityModulePartner {
   _key
   _type
   backgroundColor
   partner {
     logo {
      ...ImageDataPartner
     }
     url
     name
   }
   spaceAbove
   spaceBelow
 }
         ... on SanityModuleList {
           _key
           _type
           spaceAbove
           spaceBelow
           backgroundColor
           subTitle
           title
           type
           items
         }
 ... on SanityModuleTextCentered {
   _key
   _type
   spaceAbove
   spaceBelow
   backgroundColor
   subTitle
   title
 }
 ... on SanityModuleDemodesk {
   _key
   _type
   spaceAbove
   spaceBelow
   backgroundColor
   account
   event
 }
 ... on SanityModuleCalendly {
  _key
  _type
  spaceAbove
  spaceBelow
  backgroundColor
  title
  cookieConsent
  cookieConsentLabel
  account
  event
}
      }
    }
  }
`;
