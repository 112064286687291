import { Box, Grid } from '@theme-ui/components';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../Icon';
import ModuleWrapper from '../ModuleWrapper';
import { ReactComponent as BarDots } from './assets/bar-dots.svg';
import { ReactComponent as StrokeDottedCircle } from './assets/stroke-dotted-circle.svg';
import wave from './assets/wave.svg';

const ModuleTeaser = ({ children, spaceAbove, spaceBelow, variant, ...props }) => {
  const { scrollYProgress } = useViewportScroll();
  const translateY = useTransform(scrollYProgress, [0, 1], [400, 350]);
  const translateYRing = useTransform(scrollYProgress, [0, 1], [100, -10]);
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 120]);

  return (
    <ModuleWrapper
      spaceAbove={spaceAbove}
      spaceBelow={spaceBelow}
      variant={variant}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        '::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          zIndex: 0,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${wave})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        },
      }}
    >
      <Icon
        sx={{
          position: 'absolute',
          top: '-10vw',
          right: ['-10vw', '2vw', '2vw'],
          width: ['100px', '200px'],
          height: ['100px', '200px'],
          display: 'block',
          svg: { width: '100%', height: '100%', '*': { fill: 'backgrounds.medium' } },
        }}
      >
        <motion.div style={{ translateY: translateYRing, rotate }}>
          <StrokeDottedCircle />
        </motion.div>
      </Icon>
      <motion.div style={{ translateY }}>
        <Icon
          fill={['primary']}
          sx={{
            position: 'absolute',
            bottom: '-20%',
            left: 0,
            width: ['100px', '200px'],
            height: ['100px', '200px'],
            transform: 'rotate(-45deg)',
            display: 'block',
            svg: { width: '100%', height: '100%', '*': { fill: 'primary' } },
          }}
        >
          <BarDots />
        </Icon>
      </motion.div>

      <Grid gap={2} columns={[2, null, 12]} variant="default" {...props} sx={{ zIndex: 1, position: 'relative', ...props.sx }}>
        <Box
          sx={{
            gridColumn: ['1/-1', null, '2/12'],
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>{children}</Box>
        </Box>
      </Grid>
    </ModuleWrapper>
  );
};

ModuleTeaser.propTypes = {
  head: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  ...ModuleWrapper.propTypes,
};

ModuleTeaser.defaultProps = {
  head: null,
};

export default ModuleTeaser;
