import { AspectRatio, Box, Grid } from '@theme-ui/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../Icon';
import { ReactComponent as BarDots } from '../ModuleTeaser/assets/bar-dots.svg';
import { ReactComponent as Triangle } from '../ModuleTeaser/assets/triangle.svg';
import ModuleWrapper from '../ModuleWrapper';
const ModuleTextWithImage = ({
  children,
  imagePosition,
  showDecorativeElements,
  img,
  alt,
  title,
  styles,
  spaceAbove,
  spaceBelow,
  variant,
  ...props
}) => {
  const BarDotsAspectRatio = BarDots().props.viewBox.split(' ')[3] / BarDots().props.viewBox.split(' ')[2];
  const aspectRatio = img.asset?.metadata?.dimensions?.aspectRatio || 1;
  const image = img.asset?.gatsbyImageData 

  return (
    <ModuleWrapper spaceAbove={spaceAbove} spaceBelow={spaceBelow} variant={variant}>
      <Grid
        gap={2}
        columns={[2, null, 12]}
        sx={{
          maxWidth: [null, null, null, 134, 150],
          mx: ['10px', null, null, 'auto'],
        }}
      >
        <Box
          sx={{
            gridColumn: ['1/-1', null, imagePosition === 'left' ? '1/7' : '7/-1'],
            order: [2, null, imagePosition === 'left' ? 1 : 2],
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            gridColumn: ['1/-1', null, imagePosition === 'left' ? '8/-1' : '1/6'],
            order: [1, null, imagePosition === 'left' ? 2 : 1],
          }}
        >
          <Box sx={{ position: 'relative', mb: [0, null, showDecorativeElements ? 5 : 0] }}>
            {showDecorativeElements && (
              <>
                <Icon
                  sx={{
                    display: ['none', null, 'block'],
                    position: 'absolute',
                    width: ['10vw', null, '5vw'],
                    height: [`${10 * BarDotsAspectRatio}vw`, null, `${5 * BarDotsAspectRatio}vw`],
                    maxWidth: [null, null, '40px'],
                    maxHeight: [null, null, '124px'],
                    top: 0,
                    right: 0,
                    transform: ['translateX(10%) translateY(35px)', null, 'translateX(50%) translateY(35px)'],
                    zIndex: 1,
                    svg: { width: '100%', height: '100%', '*': { fill: 'backgrounds.dark' } },
                  }}
                >
                  <BarDots />
                </Icon>
                <Icon
                  sx={{
                    display: ['none', null, 'block'],
                    position: 'absolute',
                    maxWidth: [null, null, '180px'],
                    maxHeight: [null, null, '274px'],
                    width: ['40vw', null, '20vw'],
                    height: [`${40 * BarDotsAspectRatio}vw`, null, `${20 * BarDotsAspectRatio}vw`],
                    left: 0,
                    bottom: 0,
                    transform: [
                      'translateX(50%) translateY(40%) rotate(-90deg)',
                      null,
                      'translateX(90px) translateY(45%) rotate(-90deg)',
                    ],
                    zIndex: 0,
                    svg: { width: '100%', height: '100%', '*': { fill: 'backgrounds.medium' } },
                  }}
                >
                  <Triangle />
                </Icon>
              </>
            )}
            {image ? <AspectRatio ratio={aspectRatio}>
              <GatsbyImage 
                  image={image} 
                  width={410}
                  style={{
                    aspectRatio,
                    width: '100%'
                  }}
                alt={alt} 
                title={title} />
            </AspectRatio> : null}
            
            
          </Box>
        </Box>
      </Grid>
    </ModuleWrapper>
  );
};

ModuleTextWithImage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  imagePosition: PropTypes.oneOf(['left', 'right']),
  showDecorativeElements: PropTypes.bool,
  img: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  ...ModuleWrapper.propTypes,
};

ModuleTextWithImage.defaultProps = {
  imagePosition: 'left',
  showDecorativeElements: false,
  alt: '',
  title: '',
};

export default ModuleTextWithImage;
