import React from 'react';

import { Box, Grid } from '@theme-ui/components';
import { motion, useTransform, useScroll } from 'framer-motion';
import Icon from '../Icon';
import { ReactComponent as BarDots } from './assets/bar-dots.svg';
import { ReactComponent as HalfCircle } from './assets/half-circle.svg';

const Hero = ({ children, img }) => {
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [100, 500]);
  return (
    <Box
      sx={{
        display: ['flex'],
        width: '100vw',
        flexDirection: ['column-reverse', null, 'row'],
        height: ['80vh', null, null, 116],
        justifyContent: 'center',
        backgroundColor: 'backgrounds.medium',
        position: 'relative',
        zIndex: 0,
      }}
    >
      <Icon
        sx={{
          position: 'absolute',
          top: ['50%', null, 'auto'],
          bottom: ['auto', null, '5%'],
          left: ['0', null, '50%'],
          fontSize: '10rem',
          position: 'absolute',
          zIndex: 2,
          transform: ['translateY(-50%) translateX(-50%) rotate(25deg)', null, 'translateX(-50%) rotate(35deg)'],
          svg: { '*': { fill: 'primary' } },
        }}
      >
        <motion.div
          style={
            {
              // translateY: scale,
            }
          }
        >
          <BarDots />
        </motion.div>
      </Icon>

      <Icon
        sx={{
          position: 'absolute',
          top: ['auto', null, 'auto'],
          bottom: ['-3rem', null, 0],
          left: ['100%', null, '50%'],

          fontSize: ['9rem', null, '12rem'],
          position: 'absolute',
          zIndex: 1,
          transform: ['translateX(-120%) rotate(90deg)', null, 'translateX(-80%) rotate(0deg)'],
          svg: { '*': { fill: 'muted' } },
        }}
      >
        <HalfCircle />
      </Icon>
      <Grid
        gap={2}
        columns={12}
        variant="variant"
        sx={{
          maxWidth: [null, null, null, 134, 150],
          mx: [0, null, null, 'auto'],
          position: 'relative',
          zIndex: [2, null, 1],
          width: '100%',
          height: ['40vh', null, 'auto'],
          alignItems: ['flex-start', null, 'center'],
        }}
      >
        <Box sx={{ gridColumn: ['2 / span 10', null, '1/ span 6'], pt: [5, null, 0] }}>{children}</Box>
      </Grid>

      <Grid
        gap={[0, null, 2]}
        columns={[1, null, 2]}
        sx={{
          width: '100%',
          height: ['40vh', null, 'auto'],
          position: ['relative', null, 'absolute'],
          top: 0,
          bottom: 0,
          zIndex: 0,
        }}
        >
        
        <Box    
          sx={{
            gridColumn: ['1 / -1',null,'2 / span 1'],
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            backgroundPosition: ['center center', null, 'left center'],
            backgroundImage: [
              `url(${img?.bg480.gatsbyImageData.images.fallback.src})`,
              `url(${img?.bg672.gatsbyImageData.images.fallback.src})`,
              `url(${img?.bg768.gatsbyImageData.images.fallback.src})`,
              `url(${img?.bg1024.gatsbyImageData.images.fallback.src})`,
              `url(${img?.bg1200.gatsbyImageData.images.fallback.src})`,
              `url(${img?.ogImage.gatsbyImageData.images.fallback.src})`
            ]
          }}>
          </Box>
      </Grid>
    </Box>
  );
};

export default Hero;
